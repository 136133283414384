import dataLayerVideo, { DataLayerVideoServiceType } from './dataLayerVideo';
import gemius, { GemiusServiceType } from './gemius';
import { GjvpPlayerType, GjvpPlayerEventType } from '../../types/gjvpPlayer';
import MagazineIds from './magazineIds.json';
import * as Sentry from '@sentry/browser';

interface QueueEventDataType {
  time: number;
  videoTime: number;
  vpEvent: GjvpPlayerEventType;
  useVideoTime: boolean;
}

export interface VideoEventsType {
  categoryName: string;
  templateLocation: string;
  videoPlayerId: string;
  gemiusIdentifier: string;
  videoVpPlayer: GjvpPlayerType;
  typology: string;
}

interface MagazineIdsType {
  [key: string]: string;
}

/**
 * Nastavení eventů prop video a jejich následné zpracování
 * odeílání do Gemius Prism a GA4
 *
 * @param categoryName
 * @param videoPlayerId
 * @param templateLocation
 * @param gemiusIdentifier
 * @param videoVpPlayer
 * @param typology
 */
const videoEvents = ({
  categoryName,
  videoPlayerId,
  templateLocation,
  gemiusIdentifier,
  videoVpPlayer,
  typology,
}: VideoEventsType) => {
  const notEvents = ['vp-resize', 'video-state', 'vp-active', 'bufferChange', 'vp-inactive'];
  const magazineIds: MagazineIdsType = MagazineIds;

  let eventsQueue: QueueEventDataType[] = [];
  let eventsWereSent = false;
  let autoplayVideo = false;
  let progressCuePointsTimes: number[] = [];
  let videoState = 'stop';
  let videoId: string;
  let videoDuration = 0;
  let adDuration = 0;
  let adProgressSend = false;
  let firstInteraction = false;
  let currentVideoPlay = false;
  let adCounter = 0;
  let injectPreviousEvents: () => void | undefined;

  // service for send hit to gemius
  const gemiusService: GemiusServiceType = gemius({
    gemiusIdentifier,
    playerId: videoPlayerId,
    templateLocation,
    typology,
  });

  const dataLayerService: DataLayerVideoServiceType = dataLayerVideo({
    app: 'vp-player-3.0',
    templateLocation,
  });

  //nechceme posílat eventy když je viditelná consent lišta, je jedno na co tam uživatel klikne. Cpex máme na reflexu, zbytek by měl být didomi
  const checkCanSendEvents = () => {
    return !(
      document.body?.classList.contains('subscriptionModalOpen') ||
      window.Didomi?.notice?.isVisible()
    );
  };

  /**
   * Odeslani prvni interakce s video playerem
   *
   * @param time
   * @param interaction
   */
  const sendFirstInteraction = (time: number, interaction: string) => {
    if (!firstInteraction) {
      dataLayerService
        .playerEvent({ eventName: 'first_interaction', time, interaction })
        .then(() => {
          firstInteraction = true;
        })
        .catch(() => {});
    }
  };

  const addToEventQueue = (eventData: QueueEventDataType) => {
    eventsQueue.push(eventData);
  };
  /**
   * Získání názvu magazínu podle videoProjectId
   *
   * @returns string
   */
  const getVideoMagazineName = (): string => {
    const { videoProjectId } = videoVpPlayer.fullConfiguration;

    return magazineIds[videoProjectId] || videoPlayerId;
  };

  /**
   * Cuepoint setting for send video progress to GA4
   *
   * @param time number
   */
  const setPlayerProgress = (time: number) => {
    if (!progressCuePointsTimes.includes(time)) {
      progressCuePointsTimes.push(time);

      videoVpPlayer.setCuePoint(time, () => {
        dataLayerService.playerEvent({ eventName: 'progress', time }).catch(() => {});

        progressCuePointsTimes = progressCuePointsTimes.filter((item) => item !== time);
        setPlayerProgress(time + 10);
      });
    }
  };

  /**
   * @description - funkce pro zobrazení vrstvy při zamčeném videu v určitém čase
   *
   * @param {string} elementID - selektor prehravace
   */
  const lockedInTimeOverlay = (elementID: String) => {
    const videoPlayerElement = document.getElementById(`${elementID}`);
    const overlayElement = document.getElementById(`overlay-${elementID}`);
    if (overlayElement) {
      // Add container inline-size for floating miniplayer
      if (videoPlayerElement) {
        videoPlayerElement.style.containerType = 'inline-size';

        const isPlayerFloating = videoVpPlayer.isFloating();

        if (isPlayerFloating) {
          // Append overlay element to video player if floating
          videoPlayerElement.appendChild(overlayElement);
        }
      }

      // Make overlay visible
      overlayElement.style.visibility = 'visible';
    }
  };

  /**
   * listener pro video eventy
   * @param vpEvent
   *
   * @returns void
   */
  const eventListener = (vpEvent: GjvpPlayerEventType) => {
    const { eventName } = vpEvent;

    if (notEvents.includes(eventName)) return;

    let adId: string | undefined;
    let time: number = vpEvent.currentTime || 0;
    const eventIncludedTime = 'currentTime' in vpEvent;

    if (typeof vpEvent.adCurrentTime === 'number') {
      time = vpEvent.adCurrentTime;
    }

    const videoTime = videoVpPlayer.getPosition() ?? 0;
    time = Math.round(time);

    //mohlo by se stat ze posleme ready, a teprve pak se otevre consent, coz by nam rozbilo queue a zbytek eventu uz by se neposlal, proto resetovat eventsWereSent
    if (!checkCanSendEvents()) {
      eventsWereSent = false;
      return addToEventQueue({
        vpEvent,
        time,
        videoTime,
        useVideoTime: eventName === 'vp-request-play',
      });
    } else if (!eventsWereSent && injectPreviousEvents) {
      eventsWereSent = true;
      injectPreviousEvents();
    }

    const publishedDate = videoVpPlayer.videoObject?.publishDate;
    let shiftedPublishedDate;

    if (publishedDate) {
      const originalDate = new Date(publishedDate);
      originalDate.setHours(originalDate.getHours() + 1); // Posunutí o 1 hodinu Prague timezone

      const publishedYear = originalDate.getFullYear();
      const publishedMonth = (originalDate.getMonth() + 1).toString().padStart(2, '0');
      const publishedDay = originalDate.getDate().toString().padStart(2, '0');
      const publishedHours = originalDate.getHours().toString().padStart(2, '0');
      const publishedMinutes = originalDate.getMinutes().toString().padStart(2, '0');
      const publishedSeconds = originalDate.getSeconds().toString().padStart(2, '0');

      shiftedPublishedDate = `${publishedYear}-${publishedMonth}-${publishedDay} ${publishedHours}:${publishedMinutes}:${publishedSeconds}`;
    }

    switch (eventName) {
      // Video events
      case 'ready':
        autoplayVideo =
          videoVpPlayer.playlistVideoIndex === 0
            ? videoVpPlayer.autoStart
            : videoVpPlayer.isAutoplay;
        progressCuePointsTimes = [];
        videoDuration = vpEvent.duration as number;
        videoId = vpEvent.id as string;
        firstInteraction = false;
        currentVideoPlay = false;
        adCounter = 0;
        setPlayerProgress(10);
        videoState = 'ready';

        gemiusService
          .newProgram({
            duration: videoDuration,
            title: vpEvent.title || '',
            videoId,
            videoMagazineName: getVideoMagazineName(),
            autoplay: autoplayVideo,
            programProducer: videoVpPlayer.videoObject?.author || '',
          })
          .catch(() => {});

        dataLayerService
          .playerStart({
            author: videoVpPlayer.videoObject?.author || '',
            autoplay: autoplayVideo,
            category: categoryName,
            contentType: 'standard',
            entity: getVideoMagazineName(),
            fullscreen: videoVpPlayer.fullscreen,
            mute: videoVpPlayer.isMuted(),
            premium: 'unlocked',
            published: shiftedPublishedDate,
            tags: videoVpPlayer.videoObject?.tags || [],
            timeLength: videoDuration,
            time: 0,
            title: vpEvent.title as string,
            videoId,
          })
          .catch(() => {});

        break;
      case 'vp-request-play':
        if ('interaction' in vpEvent && vpEvent.interaction === true) {
          //na zenach se delo ze gjvp player neposilal k tomuto eventu time (jinde ano) -> tedy pokud prisel, pouzijeme cas, pokud ne, pouzijeme getPosition co nam vrati float time. Pokud neexistuje, tak defaultujeme na 0
          sendFirstInteraction(eventIncludedTime ? time : Math.floor(videoTime), 'user_play_10s');
        }
        break;
      case 'play':
        if (videoState === 'play') {
          break;
        }
        gemiusService.sendHit({ time: vpEvent.time || 0, type: 'play' }).catch(() => {});
        dataLayerService
          .playerEvent({ eventName: currentVideoPlay ? 'resume' : 'play', time })
          .then(() => {
            currentVideoPlay = true;
          })
          .catch(() => {});
        videoState = 'play';
        break;
      case 'pause':
        if (videoState === 'play') {
          videoState = 'pause';
          gemiusService.sendHit({ time, type: 'pause' }).catch(() => {});
          if (vpEvent.reason === 'user-interaction')
            dataLayerService.playerEvent({ eventName: 'pause', time }).catch(() => {});
        }
        break;
      case 'stop':
        videoState = 'stop';
        gemiusService.sendHit({ time, type: 'stop' }).catch(() => {});
        dataLayerService.playerEvent({ eventName: 'stop', time }).catch(() => {});
        break;
      case 'seek': // start seek
        dataLayerService.playerEvent({ eventName: 'seeking', time }).catch(() => {});
        break;
      case 'seeked': // end seek
        dataLayerService.playerEvent({ eventName: 'resume', time }).catch(() => {});
        setPlayerProgress(time - (time % 10) + 10);
        break;
      case 'bufferFull':
        if (time > 0) {
          dataLayerService.playerEvent({ eventName: 'buffering', time }).catch(() => {});
        }
        break;
      case 'mute':
        gemiusService.sendHit({ time, type: vpEvent.muted ? 'mute' : 'unmute' }).catch(() => {});
        dataLayerService
          .playerSettingsEvent({ eventName: 'mute', time, value: vpEvent.muted || false })
          .catch(() => {});
        sendFirstInteraction(time, 'user_sound');
        break;
      case 'fullscreen':
        dataLayerService
          .playerSettingsEvent({
            eventName: 'fullscreen',
            time,
            value: vpEvent.fullscreen as boolean,
          })
          .catch(() => {});
        sendFirstInteraction(time, 'user_full_screen');
        break;
      case 'levelsChanged':
        dataLayerService
          .playerSettingsEvent({
            eventName: 'resolution',
            time,
            value: vpEvent.level as number,
          })
          .catch(() => {});
        sendFirstInteraction(time, 'user_sound');
        break;
      case 'twenty-view':
        dataLayerService.playerEvent({ eventName: 'progress_20', time: 20 }).catch(() => {});
        break;
      case 'analytics-25%-completed':
        dataLayerService
          .playerEvent({ eventName: 'quartile_25', time: Math.round(videoDuration * 0.25) })
          .catch(() => {});
        break;
      case 'analytics-50%-completed':
        dataLayerService
          .playerEvent({ eventName: 'quartile_50', time: Math.round(videoDuration * 0.5) })
          .catch(() => {});
        break;
      case 'analytics-75%-completed':
        dataLayerService
          .playerEvent({ eventName: 'quartile_75', time: Math.round(videoDuration * 0.75) })
          .catch(() => {});
        break;
      case 'error':
        dataLayerService.playerEvent({ eventName: 'error', time }).catch(() => {});
        break;
      case 'relatedOpen':
        dataLayerService.playerEvent({ eventName: 'recommendation_open', time }).catch(() => {});
        break;
      case 'vp-video-switch':
        if (videoState !== 'end') {
          dataLayerService.playerEvent({ eventName: 'end', time }).catch(() => {});
          videoState = 'end';
        }
        break;
      case 'nextClick':
        dataLayerService.playerEvent({ eventName: 'next', time }).catch(() => {});
        if (videoState !== 'end') {
          dataLayerService.playerEvent({ eventName: 'end', time }).catch(() => {});
          videoState = 'end';
        }
        break;
      case 'complete':
        if (videoState !== 'end') {
          dataLayerService.playerEvent({ eventName: 'end', time }).catch(() => {});
          videoState = 'end';
        }
        break;
      case 'videoLocked':
        lockedInTimeOverlay(videoVpPlayer.id);
        break;

      /* ****************
       AD EVENTS
       **************** */
      case 'adReady':
        videoState = 'ad-play';
        adCounter += 1;
        adDuration = vpEvent.adDuration || 0;

        if (typeof vpEvent.adPlayId !== 'undefined') {
          adId = vpEvent.adPlayId.toString();
        } else {
          adId = 'not received from event';
        }
        gemiusService
          .newAd({
            adId,
            duration: vpEvent.adDuration || 0,
            position: vpEvent.adPosition || 'not received from event',
            title: vpEvent.adTitle || 'not received from event',
          })
          .catch(() => {});

        adProgressSend = false;
        dataLayerService
          .playerAdStart({
            adId,
            position: adCounter,
            skipAfter: 0,
            slot: '',
            timeLength: adDuration,
            videoId,
          })
          .catch(() => {});
        break;
      case 'adSkippable':
        dataLayerService.playerAdEvent({ eventName: 'ads_skippable', time }).catch(() => {});
        break;
      case 'adSkip':
        gemiusService.sendAdHit({ time, type: 'stop' }).catch(() => {});
        dataLayerService.playerAdEvent({ eventName: 'ads_skip', time }).catch(() => {});
        break;
      case 'adClick':
        dataLayerService.playerAdEvent({ eventName: 'ads_click', time }).catch(() => {});
        break;
      case 'adPlay':
        if (vpEvent.adDuration === time) {
          time = 0;
        }
        gemiusService.sendAdHit({ time, type: 'play' }).catch(() => {});
        dataLayerService
          .playerAdEvent({ eventName: time > 0 ? 'ads_resume' : 'ads_play', time })
          .catch(() => {});

        break;
      case 'adPause':
        gemiusService.sendAdHit({ time, type: 'pause' }).catch(() => {});
        dataLayerService.playerAdEvent({ eventName: 'ads_pause', time }).catch(() => {});
        break;
      case 'adMute':
        dataLayerService.playerAdEvent({ eventName: 'ads_mute', time }).catch(() => {});
        break;
      case 'adComplete':
        gemiusService.sendAdHit({ time, type: 'complete' }).catch(() => {});
        dataLayerService.playerAdEvent({ eventName: 'ads_end', time }).catch(() => {});
        break;
      case 'adTime':
        if (!adProgressSend && time % 10 === 0 && time > 0) {
          adProgressSend = true;
          dataLayerService.playerAdEvent({ eventName: 'ads_progress', time }).catch(() => {});
        }
        if (adProgressSend && time % 10 > 0) {
          adProgressSend = false;
        }
        break;
      case 'adError':
        dataLayerService.playerAdEvent({ eventName: 'ads_error', time }).catch(() => {});
        break;
      default:
    }
  }; // eventListener END

  injectPreviousEvents = () => {
    if (!eventsQueue.length) return;
    eventsQueue.forEach(({ time, useVideoTime, videoTime, vpEvent }) => {
      if (useVideoTime) vpEvent.currentTime = videoTime;
      else vpEvent.currentTime = time;

      eventListener(vpEvent);
    });

    eventsQueue = [];
  };

  // nastaveni listeneru pro vpPlayer eventy
  // Setup obcas vraci videoVpPlayer jako undefined, osetrujeme extra podminkou
  if (videoVpPlayer !== null && videoVpPlayer !== undefined) {
    videoVpPlayer
      .on('vpEvent', (event: GjvpPlayerEventType) => {
        if (event) eventListener(event);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }
};

export default videoEvents;
